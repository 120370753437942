import React from 'react';
import './BusinessLinks.css';

const businesses = [
  { name: "Ritz Carlton", url: "https://www.ritzcarlton.com/en/hotels/jhmrz-the-ritz-carlton-maui-kapalua/overview/", logo: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741994548/ritzzz_gxuzv1.png" },
  { name: "Grand Waliea", url: "https://www.grandwailea.com/?WT.mc_id=zlada0ww1WA2psh3ggl4amphtbrd5dkt6JHMGWWA7_153682779_1003528&gad_source=1&gclid=Cj0KCQiAvvO7BhC-ARIsAGFyToVM4kChDQimKplZ_fR-H3VefZ1cVcLiI0CMN-gBdw2azYCzO7FoUH4aAuWbEALw_wcB&gclsrc=aw.ds", logo: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296132/grand_fcrhy2.png" },
  { name: "Wailea Beach Resort", url: "https://www.marriott.com/en-us/hotels/hnmmc-wailea-beach-resort-marriott-maui/overview/", logo: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296140/resort_jxwy4l.png" },
  { name: "Makoa Resorts", url: "https://makoaresorts.com", logo: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296148/logo15_ldzqhn.png" },
  { name: "Sheraton", url: "https://www.marriott.com/en-us/hotels/hnmsi-sheraton-maui-resort-and-spa/overview/", logo: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296141/sheraton_h9jk1m.png" },
  { name: "Lizya’s Luxury Vacations", url: "https://www.airbnb.com/rooms/53384200?check_in=2024-11-13&check_out=2024-11-18&guests=1&adults=6&s=2&unique_share_id=fefffbc5-41ac-4613-b9db-e1e695c0a34c", logo: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296137/logo16_e9i6p8.jpg" },
  { name: "Lani Paradise", url: "https://airbnb.com/h/lanisoceanfrontretreat", logo: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296145/logo5_nghilu.jpg" },
  { name: "Paia Inn", url: "https://paiainn.com", logo: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296146/logo12_wxpfwu.jpg" },
  { name: "Lumeria Maui", url: "https://lumeriamaui.com", logo: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296143/logo3_tvjtle.jpg" },
  // { name: "Kimos Rent A Car", url: "https://kimosrentacar.com", logo: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296136/kimos_pzeoqd.png" },
  { name: "Makoa Car Rentals", url: "https://carrentalmaui.com", logo: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296147/logo6_o9xvfu.png" },
];

const BusinessLinks = () => {
  return (
    <div className="business-links">
      <h1>CURRENTLY SERVING</h1>
      {businesses.map((business, index) => (
        <a key={index} href={business.url} target="_blank" rel="noopener noreferrer" className="business-link">
          <img src={business.logo} alt={business.name} className="business-logo" />
        </a>
      ))}
    </div>
  );
};

export default BusinessLinks;
