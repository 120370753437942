import React, { useState } from "react";
import GeneralSearch from "./GeneralSearch";
import "./GeneralSearch.css";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const PopupModal = ({ open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    className="closeButton"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <GeneralSearch />
            </DialogContent>
        </Dialog>
    );
};

const GeneralSearchPopup = () => {
    const currentUser = useSelector(state => state.user.currentUser);
    const history = useHistory();
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleOpenPopup = () => {
        if(!currentUser) {
            history.push("/signup");
        }
        else {
            setIsPopupOpen(true);
        }
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    return (
        <div className="container">
          <button 
            onClick={handleOpenPopup}
            className="openButton buttonBottom"
          >
            Submit Inquiry
          </button>
          <PopupModal open={isPopupOpen} onClose={handleClosePopup} />
        </div>
    );
};

export default GeneralSearchPopup;
