import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "./GeneralSearch.css";
import { searchListings } from "../../SearchPage/SearchPage.duck";
import { useConfiguration } from '../../../context/configurationContext';
import LocationAutocompleteInputImpl from "../../../components/LocationAutocompleteInput/LocationAutocompleteInputImpl"
import { types as sdkTypes } from '../../../util/sdkLoader';
import mapboxgl from 'mapbox-gl'; // Ensure Mapbox GL is imported and available
import { sendInquiry } from "../../ListingPage/ListingPage.duck";
import { Snackbar, Alert } from "@mui/material";
import { useHistory } from "react-router-dom";


const { LatLng: SDKLatLng, LatLngBounds: SDKLatLngBounds } = sdkTypes;




const GeneralSearch = () => {
  const tzlookup = require("tz-lookup");
  const config = useConfiguration();
  const dispatch = useDispatch();
  const history = useHistory();

  // State management for form inputs
  const [category, setCategory] = useState("");
  const [categoryDisplay, setCategoryDisplay] = useState("");
  const [hourStart, setHourStart] = useState(null);
  const [hourEnd, setHourEnd] = useState(null);
  

  const [selectedDate, setSelectedDate] = useState(null);
  const [message, setMessage] = useState("");
  const [initialMessage, setInitialMessage] = useState("");


  const [searchInProgress, setSearchInProgress] = useState(false); // Local loading state
  const [searchResults, setSearchResults] = useState([]); // Local state for listings
  const [addressInput, setAddressInput] = useState({
    search: "",
    predictions: [],
    selectedPlace: null,
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  useEffect(() => {
    const updatedMessage = `Hi I am looking for: ${categoryDisplay || "category"}, on ${
      selectedDate ? getDateDisplayValue() : "a date"
    } from ${hourStart ? (hourStart === 12 ? "12 PM" : hourStart > 12 ? `${hourStart - 12} PM` : `${hourStart} AM`) : "a start time"} 
    to ${hourEnd ? (hourEnd === 12 ? "12 PM" : hourEnd > 12 ? `${hourEnd - 12} PM` : `${hourEnd} AM`) : "an end time"} 
    in ${addressInput.selectedPlace?.address || "a location"}`;
    
    setInitialMessage(updatedMessage);
  }, [categoryDisplay, selectedDate, hourStart, hourEnd, addressInput.selectedPlace]);


  const currentUser = useSelector(state => state.user.currentUser);


  const today = new Date();

  const handleAddressChange = newValue => {
    setAddressInput(newValue);
  };

  const RADIUS_MILES_TO_METERS = 1609.34; // Conversion factor

  /**
   * Calculate a bounding box for a given center and radius
   * @param {number} lat Latitude of the center point
   * @param {number} lng Longitude of the center point
   * @param {number} radius Radius in miles
   * @returns {Object} Bounding box with NE and SW points
   */
  function calculateBoundingBox(lat, lng, radius) {



    const bounds = new mapboxgl.LngLat(lng=lng, lat=lat).toBounds(radius);

    return new SDKLatLngBounds(
      new SDKLatLng(bounds.getNorth(), bounds.getEast()),
      new SDKLatLng(bounds.getSouth(), bounds.getWest())
    );
  }

  function formatAndValidatePhoneNumber(phoneNumber) {
    if (!phoneNumber) {
      console.warn('Phone number is missing');
      return null; // Skip if no phone number
    }

    // Remove all spaces
    let cleanedNumber = phoneNumber.replace(/\D+/g, '');

    // Check if the number starts with +1
    if (cleanedNumber.startsWith('+1')) {
      cleanedNumber = cleanedNumber;
    } else if (cleanedNumber.startsWith('1')) {
      // If it starts with '1', add the '+' prefix
      cleanedNumber = `+${cleanedNumber}`;
    } else {
      // If it doesn't start with '1', prepend '+1'
      cleanedNumber = `+1${cleanedNumber}`;
    }

    // Validate the total length (e.g., +1XXXXXXXXXX -> 12 characters)
    if (cleanedNumber.length !== 12) {
      console.warn('Invalid phone number length:', cleanedNumber);
      return null; // Skip invalid numbers
    }

    return cleanedNumber;
  }

  const getDateDisplayValue = () => {
    const year = selectedDate.getFullYear();
    const month = ('0' + (selectedDate.getMonth() + 1)).slice(-2);
    const day = ('0' + selectedDate.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  const fetchProtectedData = async authorId => {
    const response = await fetch('/protectedData', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId: authorId }),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch protected data: ${response.statusText}`);
    }

    const data = await response.json();
    return data?.data?.phoneNumber;
  };

  const sendTextMessage = async (phone, orderId, inquire) => {
    const response = await fetch('/send-text', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ phone, id: orderId, inquire, sendInternalSms: false}),
    });

    if (!response.ok) {
      throw new Error(`Failed to send text: ${response.statusText}`);
    }
  }

  const sendTextInternal = async (message) => {
    const response = await fetch('/send-text-internal', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ message }),
    });

    if (!response.ok) {
      throw new Error(`Failed to send text: ${response.statusText}`);
    }
  }

  const generateHourOptions = () => {
    const hours = [];
    for (let i = 1; i <= 23; i++) {
      const displayHour = i === 12 ? '12 PM' : i > 12 ? `${i - 12} PM` : `${i} AM`;
      hours.push(<option key={i} value={i}>{displayHour}</option>);
    }
    return hours;
  };
  

  function getTimezoneByPlace(bounds) {
    if (!bounds || !bounds.ne || !bounds.sw) {
        throw new Error("Invalid input format");
    }
  
    // Calculate center point of the bounding box
    const lat = (bounds.ne.lat + bounds.sw.lat) / 2;
    const lng = (bounds.ne.lng + bounds.sw.lng) / 2;
  
    return tzlookup(lat, lng);
  }
  


  const handleSubmit = async e => {
    e.preventDefault();
    
    if(!currentUser) {
      setSnackbarMessage("You need to log in to proceed.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;    }

  
    try {
      setSearchInProgress(true);
      let successfulMessagesCount = 0;
  
      if (addressInput.selectedPlace && addressInput.selectedPlace.origin && category && selectedDate && hourStart && hourEnd) {

        const { lat, lng } = addressInput.selectedPlace.origin;
  
        const bounds = calculateBoundingBox(lat, lng, 50000); // Radius in miles

        const timezone = getTimezoneByPlace(bounds)

  
        const searchParams = {
          pub_category: category,
          location: addressInput.selectedPlace.address,
          bounds,
          hourStart,
          hourEnd,
          dates: `${getDateDisplayValue()},${getDateDisplayValue()}`,
          timezone,
          page: 1,
          perPage: 10,
          sort: "meta_reviewsAverage",
          include: ['author', 'images'],
          'fields.listing': [
            'id', 'title', 'geolocation', 'price',
            'publicData.categories', 'publicData.transactionProcessAlias',
            'publicData.unitType'
          ],
        };
        const searchListingsCall = searchListings(searchParams, config)
  
        const response = await dispatch(searchListingsCall);
        setSearchResults(response.data.data);

        for (const listing of response.data.data) {
          if (listing.relationships?.author?.data?.id?.uuid !== currentUser?.id?.uuid) {
            try {
              const messageFull = `${initialMessage}\n\n${message}`;
              const transactionId = await dispatch(sendInquiry(listing, messageFull));
              successfulMessagesCount++
              const authorId = listing.relationships?.author?.data?.id?.uuid

              if (authorId) {
                try {
                  const phoneNumber = await fetchProtectedData(authorId);
                  const formattedPhoneNumber = formatAndValidatePhoneNumber(phoneNumber);
                  if (formattedPhoneNumber) {
                    await sendTextMessage(formattedPhoneNumber, transactionId?.uuid, true);
                  } else {
                    console.warn('Invalid phone number, skipping text message.');
                  }
                } catch (error) {
                  console.error('Error processing text message:', error);
                }
              }
            } catch (error) {
                console.error("Error sending inquiry:", error);
            }
          }
        }
        if(successfulMessagesCount === 0) {
          setSnackbarMessage(`No listings were found for the searching parameters`);
          setSnackbarSeverity("error");
          setOpenSnackbar(true);
        } else {
          setSnackbarMessage(`Inquiries sent successfully for ${successfulMessagesCount} listing(s)!`);
          setSnackbarSeverity("success");
          setOpenSnackbar(true);

          const internalMessage = `New inquiries sent successfully for ${successfulMessagesCount} listing(s) from user: ${currentUser?.attributes?.profile?.displayName}. Category: ${categoryDisplay}, Date: ${selectedDate}, Time: ${hourStart} - ${hourEnd}, Location: ${addressInput.selectedPlace.address}`;
          await sendTextInternal(internalMessage);

          setTimeout(() => {
            history.push("/inbox/orders");
          }, 3000); // 3-second delay
        }
        
      } else {
        setSnackbarMessage("Please fill in all fields");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error retrieving listings or sending inquiries:", error);
    } finally {
      setSearchInProgress(false);
    }
};

  return (
    <form onSubmit={handleSubmit} className="general-search-form">
    <h2>Let Our Service Provider Get Back to You</h2>
    <p>Fill in the information below to send a message to all available service providers</p>

    <div className="first-three-inputs">
    {/* Address Input */}
    <div className="form-group">
        <label htmlFor="address">Address</label>
        <LocationAutocompleteInputImpl
            input={{
                name: "address",
                value: addressInput,
                onChange: handleAddressChange,
                onFocus: () => {},
                onBlur: () => {},
            }}
            meta={{
                valid: true,
                touched: false,
            }}
            placeholder="Enter address"
            showIcon={false}
        />
    </div>

    {/* Category Select */}
    <div className="form-group">
        <label htmlFor="category">Category</label>
        <select
            id="category"
            value={category}
            onChange={e => {
                setCategory(e.target.value);
                setCategoryDisplay(e.target.options[e.target.selectedIndex].text);
            }}
        >
            <option value="">Select a category</option>
            <option value="tutti-helper">Tutti Helper</option>
            <option value="babysitter">Babysitter</option>
            <option value="activities">Activities</option>
            <option value="private-chef">Chefs and Fresh Produce</option>
            <option value="health_beauty">Health & Beauty</option>
            <option value="concierge">Concierge</option>
            <option value="rentals">Rentals</option>
            <option value="photographer">Photography</option>
        </select>
    </div>
  </div>

  {/* NEW: Row for Date & Time Pickers */}
  <div className="date-time-row">
      {/* Date Picker */}
      <div className="form-group">
          <label htmlFor="date">Select Date</label>
          <DatePicker
              selected={selectedDate}
              onChange={date => setSelectedDate(date)}
              dateFormat="MMMM d, yyyy"
              placeholderText="Select a date"
              minDate={today}
          />
      </div>

      {/* Start Time Picker */}
      <div className="form-group">
          <label htmlFor="hourStart">Start Time</label>
          <select
              id="hourStart"
              value={hourStart || ""}
              onChange={(e) => {
                  const value = parseInt(e.target.value);
                  setHourStart(value);
                  if (hourEnd && value >= hourEnd) {
                      setHourEnd(null); // Reset end hour if invalid
                  }
              }}
          >
              <option value="">Select Start Time</option>
              {generateHourOptions()}
          </select>
      </div>

      {/* End Time Picker */}
      <div className="form-group">
          <label htmlFor="hourEnd">End Time</label>
          <select
              id="hourEnd"
              value={hourEnd || ""}
              onChange={(e) => setHourEnd(parseInt(e.target.value))}
              disabled={!hourStart} // Disable until start time is selected
          >
              <option value="">Select End Time</option>
              {generateHourOptions().filter(option => option.key > hourStart)}
          </select>
      </div>
  </div>


    {addressInput.selectedPlace && addressInput.selectedPlace.origin && category && selectedDate && hourStart && hourEnd &&
      <div className="form-group initial-message" >
        <p className="initial-message"><strong>{initialMessage}</strong></p>
      </div>
    }              
    {/* Message */}
    <div className="form-group message-row">
        <label htmlFor="message">Additional Info</label>
        <textarea
            id="message"
            value={message}
            onChange={e => setMessage(e.target.value)}
            placeholder="Write your message here"
            rows="4"
        ></textarea>
    </div>

    {/* Submit Button */}
    <button type="submit" className="search-all-button" disabled={searchInProgress}>
        {searchInProgress ? "Loading..." : "Send Inquiry"}
    </button>
    <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
    >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
        </Alert>
    </Snackbar>
  </form>

  );
};

export default GeneralSearch;