import React, { useState, useEffect, useRef } from 'react';
import './Testimonial.css';

const testimonials = [
  {
    name: 'Francesca G',
    picture: '/static/images/profile7.jpeg',
    review: `Tutti helper is a game changer for the vacation rental industry. It's the first time that I felt relaxed in a vacation rental.`,
    location: 'Lisbon',
  },
  {
    name: 'Michael C',
    picture: '/static/images/profile1.jpeg',
    review: 'Adriana was so helpful and responsible. She appeared to be very experienced. I booked her throughout my entire vacation at my Airbnb. My kids adore her.',
    location: 'Montreal',
  },
  {
    name: 'Anna s',
    picture: '/static/images/profile4.jpeg',
    review: `Betsy came ready for our three high-energy littles. She even brought citrus from her backyard tree! She is a gem. We are so grateful to have met her though Tutti.`,
    location: 'Indiana',
  },
  {
    name: 'Ron I',
    picture: '/static/images/profile6.jpeg',
    review: 'Molly was amazing, we booked 5 lessons with her for our 9-year-old son. It was a super experience, and his surfing really improved while we felt safe knowing that Molly was there with him.',
    location: 'Maui',
  },
  {
    name: 'Klil B',
    picture: '/static/images/profile6.jpeg',
    review: 'I used Tutti several times! This is a Genius app! I could find my Tutti helper that helped me with my kids with cleaning up the house and folding the laundry! I also ordered photographer and it was great and so convenient! I will definitely use this app in my next vacation!',
    location: 'Haiku Maui',
  },
  {
    name: 'Pearl - Airbnb co host',
    picture: '/static/images/profile6.jpeg',
    review: 'We recommend Tutti to all of our Maui guests and they love it. Tutti vacation is a reliable, and convenient way for travelers to book the local services they need.',
    location: 'Maui',
  },
  {
    name: 'Yonit A',
    picture: '/static/images/profile8.jpg',
    review: 'Charleston was amazing! She was on-time, enthusiastic, and sweet with the kids. We felt very comfortable leaving our kids with her and will absolutely use this service again.',
    location: 'Las Vegas',
  },
  {
    name: 'Steven S',
    picture: '/static/images/profile.jpg',
    review: 'Great service and experience. The rented things were in great shape and very clean.',
    location: 'Rome',
  },
  {
    name: 'Carolina C',
    picture: '/static/images/profile9.jpeg',
    review: `Sailor is an amazing baker, communicated well and was very professional. I highly recommend booking her!`,
    location: 'New York',
  },
  {
    name: 'Ron B',
    picture: '/static/images/profile6.jpeg',
    review: 'The platform made it easy to connect with verified local babysitters, allowing us to review profiles and select the perfect match for our family. Our chosen sitter was professional, experienced, and created a safe, fun environment for our children.',
    location: 'Tel Aviv',
  },
  {
    name: 'Lani Paradise',
    picture: '/static/images/profile2.jpeg',
    review: 'Samson was a pleasure to work with! He delivered coconuts to my guests at my vacation rental and they were so happy! I’d highly recommend booking Samson!',
    location: 'Maui',
  },
  {
    name: 'James T',
    picture: '/static/images/profile2.jpeg',
    review: 'Charlie is a dream. Our three kids took to her instantly. We’ve already hired her again three days later!',
    location: 'Santa Barbara',
  },
  {
    name: 'Audrey C ',
    picture: '/static/images/profile2.jpeg',
    review: 'Mia incorporated both of her interests on our next time working with her. She brought art supplies and we added on an art activity for Mia to do with my daughter. Before leaving Mia surprised my daughter with books about her favorite things princesses, fairytales and also local books she had picked up sharing about Hawaiian culture in a fun way.',
    location: 'Colorado',
  },
  {
    name: 'Jessie M',
    picture: '/static/images/profile2.jpeg',
    review: 'My family and I stayed at the Grand Wailea and absolutely loved our babysitters from Tutti! Our 4 month old immediately took to them. And my husband and I were able to enjoy the day at the pool and beach. Tutti went above and beyond offering to bring me medicine when I got food poisoning. Highly recommend this company!',
    location: 'Los Angeles',
  },

];

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const intervalRef = useRef(null);

  const startAutoSlide = () => {
    intervalRef.current = setInterval(() => {
      nextSlide();
    }, 20000);
  };

  const stopAutoSlide = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const nextSlide = () => {
    if (isMobile) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    } else {
      setCurrentIndex((prevIndex) => (prevIndex + 3) % testimonials.length);
    }
  };

  const prevSlide = () => {
    if (isMobile) {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
      );
    } else {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? testimonials.length - 3 : prevIndex - 3
      );
    }
  };

  const handleNextClick = () => {
    stopAutoSlide();
    nextSlide();
    startAutoSlide();
  };

  const handlePrevClick = () => {
    stopAutoSlide();
    prevSlide();
    startAutoSlide();
  };

  useEffect(() => {
    const mediaQueryList = window.matchMedia('(max-width: 767px)');
    setIsMobile(mediaQueryList.matches);

    function updateIsMobile(e) {
      setIsMobile(e.matches);
    }
    mediaQueryList.addEventListener('change', updateIsMobile);

    return function cleanup() {
      mediaQueryList.removeEventListener('change', updateIsMobile);
    };
  });

  useEffect(() => {
    startAutoSlide();
    return () => stopAutoSlide();
  }, []);

  const displayedTestimonials = isMobile
    ? [testimonials[currentIndex]]
    : [
        testimonials[currentIndex],
        testimonials[(currentIndex + 1) % testimonials.length],
        testimonials[(currentIndex + 2) % testimonials.length],
      ];

  return (
    <div>
      <h2 className="testimonial-title">WHAT OUR USERS SAY</h2>
    <div className="testimonial-container">
      <button className="arrow left-arrow" onClick={handlePrevClick}>
        &#10094;
      </button>
      <div className="testimonial-carousel">
        {displayedTestimonials.map((testimonial, index) => (
          <div className="testimonial-item" key={index}>
            <div className="testimonial-stars">
              {'⭐️⭐️⭐️⭐️⭐️'}
            </div>
            <div className="testimonial-content">
              <p className="testimonial-review">{testimonial.review}</p>
            </div>
            <div className="testimonial-footer">
              <p className="testimonial-name">{testimonial.name}</p>
              <p className="testimonial-location">- {testimonial.location}</p>
            </div>
          </div>
        ))}
      </div>
      <button className="arrow right-arrow" onClick={handleNextClick}>
        &#10095;
      </button>
    </div>
    </div>
  );
};

export default Testimonial;
