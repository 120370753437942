import React from "react";
import "./PhoneLink.css";

const PhoneLink = () => {
  return (
    <div className="phoneLink">
      <a href="tel:+18082980235" className="phone-link-number">
        Contact us at: +1 808-298-0235
      </a>
    </div>
  );
};

export default PhoneLink;
